import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import Register from './components/Register';
import VerifyOTP from './components/VerifyOTP';
import Login from './components/Login';
import Home from './components/Home';
import PrivateRoute from './components/PrivateRoute';
import ChangePassword from './components/ChangePassword';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Videoframe from './components/Videoframe';
import InterviewForm from './components/Interviewform';

const App = () => {
  // State to check if the form has been submitted
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Callback to update the state after form submission
  const handleFormSubmit = () => {
    console.log("Form submitted, switching to Videoframe");
    setIsFormSubmitted(true);
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Routes from app1.js */}
          <Route
            path="/interview/:interview_id"
            element={<InterviewForm onSubmit={handleFormSubmit} />}
          />
          <Route
            path="/video/:interview_id/:candidate_id"
            element={<Videoframe />}
          />

          {/* Routes from app2.js */}
          <Route path="/register" element={<Register />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/change-password" element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          } />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;