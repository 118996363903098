import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function InterviewForm() {
  const { interview_id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    status: "",
    resume: null,
  });

  const navigate = useNavigate();
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    // Fetch CSRF token when component mounts
    const fetchCsrfToken = async () => {
      try {
        const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
        // const apiinterviewUrl = 'http://127.0.0.1:8000';
        const response = await fetch(`${apiinterviewUrl}/api/get-csrf-token/`, {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setCsrfToken(data.csrfToken);
        }
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    fetchCsrfToken();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({ ...prevData, resume: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataObj = new FormData();
    const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
    // const apiinterviewUrl = 'http://127.0.0.1:8000';
    formDataObj.append("name", formData.name);
    formDataObj.append("email", formData.email);
    formDataObj.append("country", formData.country);
    formDataObj.append("status", formData.status);
    formDataObj.append("resume_key", formData.resume);
    formDataObj.append("token", interview_id);

    try {
      const response = await fetch(`${apiinterviewUrl}/api/interview/${interview_id}`, {
        method: "POST",
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrfToken,
        },
        body: formDataObj,
      });

      if (response.ok) {
        const data = await response.json();
        const candidate_id = data.candidate_id;

        // Store session data
        sessionStorage.setItem('interviewSession', JSON.stringify({
          interview_id,
          candidate_id,
          timestamp: new Date().getTime()
        }));

        navigate(`/video/${interview_id}/${candidate_id}`);
      } else {
        alert("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </label>
        <br />
        <label>
          country:
          <input type="text" name="country" value={formData.country} onChange={handleChange} required />
        </label>
        <br />
        <label>
          status:
          <input type="text" name="status" value={formData.status} onChange={handleChange} required />
        </label>
        <br />
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <br />
        <label>
          Resume:
          <input type="file" name="resume" onChange={handleFileChange} required />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
  );
}

export default InterviewForm;
