import React, { useState, useEffect } from 'react';
import api from '../services/api';

const CandidateDetails = ({ candidateId }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await api.get(`/candidates/${candidateId}/`);
        setDetails(response.data);
      } catch (err) {
        setError('Failed to fetch candidate details');
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [candidateId]);

  if (loading) return <div className="p-4">Loading details...</div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;
  if (!details) return null;

  const { submission, details: candidateDetails } = details;

  return (
    <div className="bg-white p-6 rounded shadow-sm space-y-6">
      <div>
        <h4 className="text-lg font-semibold mb-2">Interview Recordings</h4>
        <div className="space-y-2">
          <a 
            href={submission.video_recording_key}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline block"
          >
            View Video Recording
          </a>
          <a 
            href={submission.screen_recording_key}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline block"
          >
            View Screen Recording
          </a>
        </div>
      </div>

      <div>
        <h4 className="text-lg font-semibold mb-2">Assessment Summaries</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h5 className="font-medium">Work Experience</h5>
            <p className="text-sm">{candidateDetails.Work_experience_summary}</p>
            <p className="text-sm font-medium mt-1">Score: {candidateDetails.Work_experience_score}</p>
          </div>
          <div>
            <h5 className="font-medium">Behavioral Insights</h5>
            <p className="text-sm">{candidateDetails.Behavioral_and_Situational_Insights_summary}</p>
            <p className="text-sm font-medium mt-1">Score: {candidateDetails.Behavioral_and_Situational_Insights_score}</p>
          </div>
          {/* Add similar sections for other summaries */}
        </div>
      </div>

      <div>
        <h4 className="text-lg font-semibold mb-2">Final Assessment</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p><strong>Years of Experience:</strong> {candidateDetails.year_of_experience}</p>
            <p><strong>Average Tenure:</strong> {candidateDetails.average_tenure}</p>
          </div>
          <div>
            <p><strong>Strong Skills:</strong> {candidateDetails.strong_skills}</p>
            <p><strong>Soft Skills:</strong> {candidateDetails.soft_skills}</p>
            <p><strong>Salary Estimation:</strong> {candidateDetails.salary_estimation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDetails;