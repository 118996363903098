import React, { useState, useEffect } from 'react';
import api from '../services/api';
import CandidateDetails from './CandidateDetails';

const CandidatesList = ({ listingToken }) => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await api.get(`/listings/${listingToken}/candidates/`);
        setCandidates(response.data);
      } catch (err) {
        setError('Failed to fetch candidates');
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  }, [listingToken]);

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(selectedCandidate?.candidate_id === candidate.candidate_id ? null : candidate);
  };

  if (loading) return <div className="p-4">Loading candidates...</div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold">Candidates</h3>
      {candidates.length === 0 ? (
        <p className="text-gray-500">No candidates yet</p>
      ) : (
        candidates.map((candidate) => (
          <div key={candidate.candidate_id} className="space-y-2">
            <div 
              className="bg-gray-50 p-4 rounded cursor-pointer hover:bg-gray-100"
              onClick={() => handleCandidateClick(candidate)}
            >
              <p><strong>Name:</strong> {candidate.name}</p>
              <p><strong>Custom summary:</strong> {candidate.custom_summary}</p>
              <p><strong>Custom score:</strong> {candidate.custom_score}</p>
              <p><strong>Submitted:</strong> {new Date(candidate.created_at).toLocaleString()}</p>
            </div>
            
            {selectedCandidate?.candidate_id === candidate.candidate_id && (
              <CandidateDetails candidateId={candidate.candidate_id} />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default CandidatesList;