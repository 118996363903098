import React, { useState } from 'react';

const ListingModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    job_title:'',
    emails: '',
    requirements: '',
    valid_days: ''
  });
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formData);
      onClose();
      setFormData({ job_title: '', emails: '', requirements: '', valid_days: '' });
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to create listing');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Create New Listing</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Job Title
            </label>
            <input
              type="text"
              value={formData.job_title}
              onChange={(e) => setFormData({...formData, job_title: e.target.value})}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Emails (comma separated)
            </label>
            <input
              type="text"
              value={formData.emails}
              onChange={(e) => setFormData({...formData, emails: e.target.value})}
              className="w-full p-2 border rounded"
              placeholder="email1@example.com, email2@example.com"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Requirements
            </label>
            <textarea
              value={formData.requirements}
              onChange={(e) => setFormData({...formData, requirements: e.target.value})}
              className="w-full p-2 border rounded"
              rows="4"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Valid Days
            </label>
            <input
              type="number"
              value={formData.valid_days}
              onChange={(e) => setFormData({...formData, valid_days: e.target.value})}
              className="w-full p-2 border rounded"
              min="1"
              required
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Create Listing
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ListingModal;