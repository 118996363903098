import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";


const Videoframe = () => {

    const { interview_id } = useParams();
    const { candidate_id } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState('');
    const [completeTranscript, setCompleteTranscript] = useState('');
    const [connectionStatus, setConnectionStatus] = useState('disconnected');
    const [isAiSpeaking, setIsAiSpeaking] = useState(false);
    const [canUserRespond, setCanUserRespond] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');
    const [timeRemaining, setTimeRemaining] = useState(30 * 60);
    const [timerActive, setTimerActive] = useState(false);
    const [displayedQuestion, setDisplayedQuestion] = useState('');
    const streamTimeoutRef = useRef(null);
    const initialSubmissionTimeoutRef = useRef(null);
    const isFirstQuestionRef = useRef(true);
    const navigate = useNavigate();

    const [aiQuestion, setAiQuestion] = useState('');  // Add this for AI questions
    const [interimTranscript, setInterimTranscript] = useState('');  // Add this for real-time transcription
    const [isTestMode, setIsTestMode] = useState(true);  // Add this for audio/video test
    const [isAudioVideoWorking, setIsAudioVideoWorking] = useState(false);
    const [sessionHistory, setSessionHistory] = useState([]);
    const [sessionValid, setSessionValid] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    
    const videoRef = useRef(null);
    const audioRef = useRef(new Audio());
    const websocketRef = useRef(null);
    const recognitionRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const screenRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const screenStreamRef = useRef(null);
    const audioContextRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const recordedChunksRef = useRef({
        video: [],
        screen: []
    });

    const uploadStateRef = useRef({
        video: {
            uploadId: null,
            key: null,
            parts: [],
            nextPartNumber: 1,
            pendingChunks: [],
            currentChunkSize: 0,
            accumulatedChunks: []
        },
        screen: {
            uploadId: null,
            key: null,
            parts: [],
            nextPartNumber: 1,
            pendingChunks: [],
            currentChunkSize: 0,
            accumulatedChunks: []
        }
    });

    const [uploadingState, setUploadingState] = useState('idle'); // idle, uploading, completed, error
    const chunkSize = 5 * 1024 * 1024; // 5MB chunks
    const uploadProgressRef = useRef({
        video: { uploaded: 0, total: 0 },
        screen: { uploaded: 0, total: 0 }
    });
    
    const uploadChunkWithRetry = async (url, chunk, maxRetries = 3) => {
        for (let i = 0; i < maxRetries; i++) {
            try {
                const uploadResponse = await fetch(url, {
                    method: 'PUT',
                    body: chunk
                });
                if (uploadResponse.ok) return uploadResponse;
            } catch (error) {
                if (i === maxRetries - 1) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
            }
        }
    };

    const getPresignedUrl = async (uploadId, partNumber, key, maxRetries = 3) => {
        // const apiinterviewUrl = 'http://127.0.0.1:8000';
        const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
        for (let i = 0; i < maxRetries; i++) {
            try {
                const urlResponse = await fetch(
                    
                    `${apiinterviewUrl}/api/get-upload-part-url/`, 
                    {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken
                    },
                    credentials: 'include',
                    body: JSON.stringify({ uploadId, partNumber, key })
                });
                if (urlResponse.ok) {
                    return await urlResponse.json();
                }
            } catch (error) {
                if (i === maxRetries - 1) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
            }
        }
        throw new Error('Failed to get presigned URL after retries');
    };

    const initializeUpload = async (fileType) => {
        try {
            const fileName = `${fileType}_${new Date().getTime()}.webm`;
            const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
            // const apiinterviewUrl = 'http://127.0.0.1:8000';
            
            const initResponse = await fetch(`${apiinterviewUrl}/api/init-multipart-upload/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                credentials: 'include',
                body: JSON.stringify({
                    fileName,
                    fileType: 'video/webm',
                    interviewId: interview_id,
                    candidate_id: candidate_id
                })
            });
            
            const { uploadId, key } = await initResponse.json();
            uploadStateRef.current[fileType] = {
                ...uploadStateRef.current[fileType],
                uploadId,
                key
            };
            return { uploadId, key };
        } catch (error) {
            console.error(`Failed to initialize ${fileType} upload:`, error);
            throw error;
        }
    };
    
    const processAccumulatedChunks = async (type, forceProcess = false) => {
        const state = uploadStateRef.current[type];
        const minChunkSize = 5 * 1024 * 1024; // 5MB minimum for S3
    
        if (state.accumulatedChunks.length > 0) {
            // If forcing process or we have enough data
            if (forceProcess || state.currentChunkSize >= minChunkSize) {
                // Create blob from accumulated chunks
                const blob = new Blob(state.accumulatedChunks, { type: 'video/webm' });
                
                // For final chunks that are too small, try to combine with previous pending chunk
                if (forceProcess && blob.size < minChunkSize && state.pendingChunks.length > 0) {
                    const previousChunk = state.pendingChunks.pop();
                    const combinedBlob = new Blob([previousChunk, blob], { type: 'video/webm' });
                    state.pendingChunks.push(combinedBlob);
                } else {
                    state.pendingChunks.push(blob);
                }
                
                // Reset accumulation
                state.accumulatedChunks = [];
                state.currentChunkSize = 0;
                
                // Try to upload
                await uploadPendingChunks(type);
            }
        }
    };
    
    const uploadPendingChunks = async (fileType, maxRetries = 3, isEndSession = false) => {
        const state = uploadStateRef.current[fileType];
        let retryCount = 0;
        const minChunkSize = 5 * 1024 * 1024; // 5MB
        
        // First, ensure all chunks except last one are 5MB
        if (state.pendingChunks.length > 1) {
            const reorganizedChunks = [];
            let currentChunk = new Blob([], { type: 'video/webm' });
            let currentSize = 0;
            
            // Process all chunks except the last one
            for (let i = 0; i < state.pendingChunks.length - 1; i++) {
                const chunk = state.pendingChunks[i];
                const remainingSpace = minChunkSize - currentSize;
                
                if (chunk.size <= remainingSpace) {
                    // Add entire chunk to current accumulation
                    currentChunk = new Blob([currentChunk, chunk], { type: 'video/webm' });
                    currentSize += chunk.size;
                } else {
                    // Split the chunk
                    const firstPart = chunk.slice(0, remainingSpace);
                    const secondPart = chunk.slice(remainingSpace);
                    
                    // Complete current chunk
                    currentChunk = new Blob([currentChunk, firstPart], { type: 'video/webm' });
                    reorganizedChunks.push(currentChunk);
                    
                    // Start new chunk with remaining data
                    currentChunk = new Blob([secondPart], { type: 'video/webm' });
                    currentSize = secondPart.size;
                }
                
                // If we have a full chunk, add it to reorganized chunks
                if (currentSize >= minChunkSize) {
                    reorganizedChunks.push(currentChunk);
                    currentChunk = new Blob([], { type: 'video/webm' });
                    currentSize = 0;
                }
            }
            
            // Handle any remaining data before the last chunk
            if (currentSize > 0) {
                reorganizedChunks.push(currentChunk);
            }
            
            // Add the last chunk as is
            reorganizedChunks.push(state.pendingChunks[state.pendingChunks.length - 1]);
            
            // Replace original chunks with reorganized ones
            state.pendingChunks = reorganizedChunks;
        }
        
        // Now proceed with upload
        while (state.pendingChunks.length > 0) {
            const chunk = state.pendingChunks[0];
            const isLastChunk = state.pendingChunks.length === 1;
            let uploaded = false;
            
            // Verify chunk size (except for last chunk)
            if (!isLastChunk && chunk.size !== minChunkSize) {
                console.error(`Invalid chunk size detected: ${chunk.size} bytes. Expected: ${minChunkSize} bytes`);
                if (isEndSession) {
                    // During end session, try to fix the chunk size
                    try {
                        if (chunk.size < minChunkSize && state.pendingChunks.length > 1) {
                            // Combine with next chunk
                            const nextChunk = state.pendingChunks[1];
                            const combinedChunk = new Blob([chunk, nextChunk], { type: 'video/webm' });
                            if (combinedChunk.size >= minChunkSize) {
                                const properChunk = combinedChunk.slice(0, minChunkSize);
                                const remainder = combinedChunk.slice(minChunkSize);
                                state.pendingChunks[0] = properChunk;
                                state.pendingChunks[1] = remainder;
                            }
                        }
                    } catch (error) {
                        console.error('Error fixing chunk size:', error);
                    }
                }
            }
            
            while (!uploaded && retryCount < maxRetries) {
                try {
                    const { url } = await getPresignedUrl(state.uploadId, state.nextPartNumber, state.key);
                    const uploadResponse = await uploadChunkWithRetry(url, chunk);
                    
                    if (uploadResponse.ok) {
                        state.pendingChunks.shift();
                        state.parts.push({
                            PartNumber: state.nextPartNumber,
                            ETag: uploadResponse.headers.get('ETag').replace(/"/g, '')
                        });
                        state.nextPartNumber++;
                        
                        // Update progress
                        uploadProgressRef.current[fileType].uploaded += chunk.size;
                        const totalSize = uploadProgressRef.current.video.total + uploadProgressRef.current.screen.total;
                        const totalUploaded = uploadProgressRef.current.video.uploaded + uploadProgressRef.current.screen.uploaded;
                        const combinedProgress = (totalUploaded / totalSize) * 100;
                        setUploadProgress(Math.min(combinedProgress, 99));
                        
                        uploaded = true;
                        console.log(`Successfully uploaded ${fileType} chunk ${state.nextPartNumber - 1}, size: ${chunk.size} bytes`);
                    } else {
                        throw new Error(`Upload failed with status: ${uploadResponse.status}`);
                    }
                } catch (error) {
                    console.error(`Failed to upload chunk for ${fileType} (attempt ${retryCount + 1}):`, error);
                    retryCount++;
                    
                    if (retryCount < maxRetries) {
                        // Exponential backoff
                        await new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount) * 1000));
                    } else if (isEndSession && isLastChunk && chunk.size < minChunkSize) {
                        // Special handling for small final chunk during end session
                        try {
                            if (state.parts.length > 0) {
                                console.log(`Attempting to recover ${fileType} upload by combining with previous chunk...`);
                                
                                // Request the previous chunk
                                const prevPartNumber = state.nextPartNumber - 1;
                                const { url: prevUrl } = await getPresignedUrl(state.uploadId, prevPartNumber, state.key);
                                const prevResponse = await fetch(prevUrl);
                                const prevChunk = await prevResponse.blob();
                                
                                // Combine chunks
                                const combinedChunk = new Blob([prevChunk, chunk], { type: 'video/webm' });
                                state.pendingChunks[0] = combinedChunk;
                                
                                // Remove the previous part
                                state.parts.pop();
                                state.nextPartNumber--;
                                
                                // Reset retry count to try again with combined chunk
                                retryCount = 0;
                                continue;
                            }
                        } catch (recoveryError) {
                            console.error(`Recovery attempt failed for ${fileType}:`, recoveryError);
                        }
                    }
                }
            }
            
            if (!uploaded) {
                throw new Error(`Failed to upload chunk for ${fileType} after ${maxRetries} attempts`);
            }
        }
    };

    const uploadFileInChunks = async (blob, fileType) => {
        try {
            const fileName = `${fileType}_${new Date().getTime()}.webm`;
            const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
            // const apiinterviewUrl = 'http://127.0.0.1:8000';
            
            // Initialize multipart upload
            uploadProgressRef.current[fileType] = {
                uploaded: 0,
                total: blob.size
            };
            const initResponse = await fetch(`${apiinterviewUrl}/api/init-multipart-upload/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                credentials: 'include',
                body: JSON.stringify({
                    fileName,
                    fileType: blob.type,
                    interviewId: interview_id,
                    candidate_id: candidate_id
                })
            });
            const { uploadId, key } = await initResponse.json();
    
            // Split file into chunks
            const chunks = [];
            let start = 0;
            while (start < blob.size) {
                const end = Math.min(start + chunkSize, blob.size);
                chunks.push(blob.slice(start, end));
                start = end;
            }
    
            
            // Upload each chunk
            const uploadPromises = chunks.map(async (chunk, index) => {
                const partNumber = index + 1;
                
                const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
                // const apiinterviewUrl = 'http://127.0.0.1:8000';
                // Get presigned URL for this part
                const getPresignedUrl = async (uploadId, partNumber, key, maxRetries = 3) => {
                    for (let i = 0; i < maxRetries; i++) {
                        try {
                            const urlResponse = await fetch(`${apiinterviewUrl}/api/get-upload-part-url/`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'X-CSRFToken': csrfToken
                                },
                                credentials: 'include',
                                body: JSON.stringify({ uploadId, partNumber, key })
                            });
                            if (urlResponse.ok) return await urlResponse.json();
                        } catch (error) {
                            if (i === maxRetries - 1) throw error;
                            await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
                        }
                    }
                };
                const { url } = await getPresignedUrl(uploadId, partNumber, key);
    
                // Upload chunk
                const uploadResponse = await fetch(url, {
                    method: 'PUT',
                    body: chunk
                });

                uploadProgressRef.current[fileType].uploaded += chunk.size;

                // Calculate combined progress from both files
                const totalSize = uploadProgressRef.current.video.total + uploadProgressRef.current.screen.total;
                const totalUploaded = uploadProgressRef.current.video.uploaded + uploadProgressRef.current.screen.uploaded;
                const combinedProgress = (totalUploaded / totalSize) * 100;
                setUploadProgress(Math.min(combinedProgress, 99)); // Cap at 99% until complete
    
                // Update progress

                // uploadedSize += chunk.size;
                // uploadProgressRef.current[fileType].uploaded = uploadedSize;
                
                // Calculate combined progress from both files
                // const totalUploaded = uploadProgressRef.current.video.uploaded + uploadProgressRef.current.screen.uploaded;
                // const combinedProgress = (totalUploaded / totalSize) * 100;
                // setUploadProgress(Math.min(combinedProgress, 99)); // Cap at 99% until complete
    
                // Return part information
                return {
                    PartNumber: partNumber,
                    ETag: uploadResponse.headers.get('ETag').replace(/"/g, '')
                };
            });
    
            // Wait for all chunks to upload
            const parts = await Promise.all(uploadPromises);
            
            // Complete multipart upload
            const completeResponse = await fetch(
                // 'http://127.0.0.1:8000/api/complete-multipart-upload/',{
                `${process.env.REACT_APP_INTERVIEW_API}/api/complete-multipart-upload/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                credentials: 'include',
                body: JSON.stringify({
                    uploadId,
                    key,
                    parts
                })
            });
    
            const result = await completeResponse.json();
            
            // Return both the parts and the location
            return {
                parts,
                location: key // Using the S3 key as the location
            };
        } catch (error) {
            console.error('Upload error:', error);
            throw error;
        }
    };



    const streamText = (text) => {
        const words = text.split(' ');
        let wordIndex = 0;
        setDisplayedQuestion('');
        
        const stream = () => {
            if (wordIndex < words.length) {
                setDisplayedQuestion(prev => prev + words[wordIndex] + ' ');
                wordIndex++;
                streamTimeoutRef.current = setTimeout(stream, 200); // Adjust speed as needed
            }
        };
        
        stream();
    };

    const testAudioVideo = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }

            // Test audio recording
            const audioContext = new AudioContext();
            const audioSource = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            audioSource.connect(analyser);

            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            let audioDetected = false;

            const checkAudio = () => {
                analyser.getByteFrequencyData(dataArray);
                const audioLevel = dataArray.reduce((a, b) => a + b) / dataArray.length;
                if (audioLevel > 30) { // Threshold for audio detection
                    audioDetected = true;
                }
            };

            // Check audio for 3 seconds
            let checksCount = 0;
            const audioInterval = setInterval(() => {
                checkAudio();
                checksCount++;
                if (checksCount >= 30) { // 3 seconds (10 checks per second)
                    clearInterval(audioInterval);
                    const isWorking = audioDetected && stream.getVideoTracks()[0].enabled;
                    setIsAudioVideoWorking(isWorking);
                    stream.getTracks().forEach(track => track.stop());
                }
            }, 100);

        } catch (error) {
            console.error('Error testing audio/video:', error);
            setIsAudioVideoWorking(false);
        }
    };
    
    // Timer effect
    useEffect(() => {
        if (timerActive && timeRemaining > 0) {
            timerRef.current = setInterval(() => {
                setTimeRemaining(prev => {
                    if (prev <= 1) {
                        endSession();
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [timerActive]);

    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            cleanupAllResources();
        };
    }, []);

    useEffect(() => {
        try {
            const sessionData = JSON.parse(sessionStorage.getItem('interviewSession'));
            if (!sessionData || 
                sessionData.interview_id !== interview_id || 
                sessionData.candidate_id !== candidate_id ||
                new Date().getTime() - sessionData.timestamp > 30 * 60 * 1000) {
                alert('Invalid or expired session. Please start over.');
                navigate('/');
                return;
            }
            setSessionValid(true);
        } catch (error) {
            console.error('Session validation error:', error);
            navigate('/');
        }
    }, [interview_id, candidate_id, navigate]);

    // Format time remaining
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const cleanupAllResources = async () => {
        try {
            // Process any remaining accumulated chunks
            // await Promise.all([
            //     processAccumulatedChunks('video', true), // Force process remaining chunks
            //     processAccumulatedChunks('screen', true)  // Force process remaining chunks
            // ]);
    
            // // Upload any remaining chunks
            // await Promise.all([
            //     uploadPendingChunks('video'),
            //     uploadPendingChunks('screen')
            // ]);
    
            // Clear references
            mediaRecorderRef.current = null;
            screenRecorderRef.current = null;
    
            // Close WebSocket connection
            // if (websocketRef.current) {
            //     websocketRef.current.close();
            //     websocketRef.current = null;
            // }
    
            // Stop speech recognition
            // if (recognitionRef.current) {
            //     recognitionRef.current.stop();
            //     recognitionRef.current = null;
            // }
        } catch (error) {
            console.error('Error in cleanup:', error);
            throw error;
        }
    };

    // Fetch CSRF token when component mounts
    useEffect(() => {
        if (!sessionValid) {
            return;
        }
        const getCsrfToken = async () => {
            try {
                const apiinterviewUrl = process.env.REACT_APP_INTERVIEW_API;
                // const apiinterviewUrl = 'http://127.0.0.1:8000';
                
                const response = await fetch(`${apiinterviewUrl}/api/get-csrf-token/`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch CSRF token');
                }

                const data = await response.json();
                if (data.csrfToken) {
                    setCsrfToken(data.csrfToken);
                } else {
                    const token = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('csrftoken='))
                        ?.split('=')[1];
                    
                    if (token) {
                        setCsrfToken(token);
                    } else {
                        console.error('CSRF token not found');
                    }
                }
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        getCsrfToken();
    }, [sessionValid]);

    const initializeWebSocket = () => {
        if (!interview_id || !candidate_id) {
            console.error('Interview Id and Candidate Id are required for WebSocket connection');
            return false;
        }

        try {
            const apiinterviewUrl_websocket = process.env.REACT_APP_INTERVIEW_API_WEBSOCKET;
            // const apiinterviewUrl_websocket = 'ws://127.0.0.1:8000';
            const wsUrl = `${apiinterviewUrl_websocket}/ws/voice/${interview_id}/${candidate_id}/`;
            websocketRef.current = new WebSocket(wsUrl);
            
            websocketRef.current.onopen = () => {
                console.log('WebSocket Connected');
                setConnectionStatus('connected');
            };

            websocketRef.current.onmessage = (e) => {
                const data = JSON.parse(e.data);
                // console.log('Received:', data);
                
                if (data.type === 'ai_response') {
                    handleAiResponse(data);
                }
                
                if (data.type === 'interview_end') {
                    // Play final response before ending session
                    if (data.audio_url) {
                        handleAiResponse(data);
                        audioRef.current.addEventListener('ended', endSession, { once: true });
                    } else {
                        endSession();
                    }
                }
            };

            websocketRef.current.onclose = () => {
                console.log('WebSocket Disconnected');
                setConnectionStatus('disconnected');
            };

            websocketRef.current.onerror = (error) => {
                console.error('WebSocket Error:', error);
                setConnectionStatus('error');
                return false;
            };

            return true;
        } catch (error) {
            console.error('Error initializing WebSocket:', error);
            setConnectionStatus('error');
            return false;
        }
    };

    const startRecording = async (stream, type) => {
        try {
            // Initialize upload first
            await initializeUpload(type);
            
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.ondataavailable = async (event) => {
                if (event.data.size > 0) {
                    // Store in recorded chunks for backup
                    recordedChunksRef.current[type].push(event.data);
                    
                    // Accumulate chunks until 5MB
                    const state = uploadStateRef.current[type];
                    state.accumulatedChunks.push(event.data);
                    state.currentChunkSize += event.data.size;
                    
                    // Process if we have enough data
                    await processAccumulatedChunks(type);
                }
            };
            
            // Request data every 1 second to check size
            mediaRecorder.start(1000);
            return mediaRecorder;
        } catch (error) {
            console.error(`Error starting ${type} recording:`, error);
            throw error;
        }
    };

    

    const startMediaStream = async () => {
        try {
            // First initialize WebSocket
            const wsInitialized = initializeWebSocket();
            if (!wsInitialized) {
                console.error('Failed to initialize WebSocket');
                return;
            }
    
            // Reset recorded chunks
            recordedChunksRef.current = {
                video: [],
                screen: []
            };
    
            // Get user stream (camera + mic)
            const userStream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });
    
            // Get screen stream
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            });
    
            // Create audio context
            const audioContext = new AudioContext();
            
            // Create sources for both streams
            const micSource = audioContext.createMediaStreamSource(userStream);
            const systemSource = audioContext.createMediaStreamSource(screenStream);
            
            // Create a mixer
            const destination = audioContext.createMediaStreamDestination();
            
            // Connect both sources to the destination
            micSource.connect(destination);
            systemSource.connect(destination);
    
            // Create new stream with screen video and mixed audio
            const combinedStream = new MediaStream([
                ...screenStream.getVideoTracks(),
                ...destination.stream.getAudioTracks()
            ]);
    
            // Store streams for cleanup
            mediaStreamRef.current = userStream;
            screenStreamRef.current = combinedStream;
    
            // Preview user's video
            if (videoRef.current) {
                videoRef.current.srcObject = userStream;
            }
    
            // Start recordings
            mediaRecorderRef.current = await startRecording(userStream, 'video');
            screenRecorderRef.current = await startRecording(combinedStream, 'screen');
    
            // Rest of your code
            setTranscript('');
            setCompleteTranscript('');
            setIsRecording(true);
            setTimerActive(true);
            if (isFirstQuestionRef.current) {
                if (initialSubmissionTimeoutRef.current) {
                    clearTimeout(initialSubmissionTimeoutRef.current);
                }
                initialSubmissionTimeoutRef.current = setTimeout(() => {
                    console.log('Auto-submitting response');
                    submitResponse();
                }, 2000);
                isFirstQuestionRef.current = false;
            } else {
                startRecognition();
            }
    
            audioRef.current.addEventListener('ended', handleAudioEnded);
    
            // Store audioContext for cleanup
            audioContextRef.current = audioContext;
    
        } catch (error) {
            console.error('Error starting media stream:', error);
            cleanupAllResources();
        }
    };

    const uploadToS3 = async () => {
        setUploadingState('uploading');
        setIsUploading(true);
        try {
            // Complete both uploads
            const completeVideoUpload = fetch(
                // 'http://127.0.0.1:8000/api/complete-multipart-upload/',
                `${process.env.REACT_APP_INTERVIEW_API}/api/complete-multipart-upload/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        uploadId: uploadStateRef.current.video.uploadId,
                        key: uploadStateRef.current.video.key,
                        parts: uploadStateRef.current.video.parts
                    })
                }
            );
    
            const completeScreenUpload = fetch(
                // 'http://127.0.0.1:8000/api/complete-multipart-upload/',
                `${process.env.REACT_APP_INTERVIEW_API}/api/complete-multipart-upload/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                        uploadId: uploadStateRef.current.screen.uploadId,
                        key: uploadStateRef.current.screen.key,
                        parts: uploadStateRef.current.screen.parts
                    })
                }
            );
    
            await Promise.all([completeVideoUpload, completeScreenUpload]);
    
            // Final completion API call
            await fetch(
                // 'http://127.0.0.1:8000/api/complete-interview-upload/',
                `${process.env.REACT_APP_INTERVIEW_API}/api/complete-interview-upload/`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken
                    },
                    body: JSON.stringify({
                        interview_id: interview_id,
                        candidate_id: candidate_id,
                        video_key: uploadStateRef.current.video.key,
                        screen_key: uploadStateRef.current.screen.key
                    })
                }
            );
    
            setUploadingState('completed');
            setUploadProgress(100);
            setIsUploading(false);
        } catch (error) {
            console.error('Upload failed:', error);
            setUploadingState('error');
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const endSession = async () => {
        // Immediately update UI state
        setTimerActive(false);
        setIsRecording(false);
        setTranscript('');
        setTimeRemaining(30 * 60);
        setConnectionStatus('disconnected');
    
        // Immediately stop preview
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject = null;
        }
    
        // Stop AI audio if playing
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.src = '';
        }
    
        // Clear all timers immediately
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
        if (streamTimeoutRef.current) {
            clearTimeout(streamTimeoutRef.current);
            streamTimeoutRef.current = null;
        }
        if (initialSubmissionTimeoutRef.current) {
            clearTimeout(initialSubmissionTimeoutRef.current);
            initialSubmissionTimeoutRef.current = null;
        }
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionRef.current = null;
        }

        
    
        try {
            // Process final chunks in background
            const processRecordings = async () => {
                try {
                    // Stop recorders first and wait for final chunks
                    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
                        await new Promise((resolve) => {
                            mediaRecorderRef.current.addEventListener('dataavailable', async (event) => {
                                if (event.data.size > 0) {
                                    const state = uploadStateRef.current.video;
                                    state.accumulatedChunks.push(event.data);
                                    state.currentChunkSize += event.data.size;
                                    try {
                                        await processAccumulatedChunks('video', true);
                                        await uploadPendingChunks('video', 3, true); // Added isEndSession flag
                                    } catch (error) {
                                        console.error('Error processing video chunks:', error);
                                    }
                                }
                                resolve();
                            }, { once: true });
                            mediaRecorderRef.current.stop();
                        });
                    }
            
                    if (screenRecorderRef.current && screenRecorderRef.current.state !== 'inactive') {
                        await new Promise((resolve) => {
                            screenRecorderRef.current.addEventListener('dataavailable', async (event) => {
                                if (event.data.size > 0) {
                                    const state = uploadStateRef.current.screen;
                                    state.accumulatedChunks.push(event.data);
                                    state.currentChunkSize += event.data.size;
                                    try {
                                        await processAccumulatedChunks('screen', true);
                                        await uploadPendingChunks('screen', 3, true); // Added isEndSession flag
                                    } catch (error) {
                                        console.error('Error processing screen chunks:', error);
                                    }
                                }
                                resolve();
                            }, { once: true });
                            screenRecorderRef.current.stop();
                        });
                    }
                    
                    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
                        mediaRecorderRef.current.stop();
                    }
                    if (screenRecorderRef.current && screenRecorderRef.current.state !== 'inactive') {
                        screenRecorderRef.current.stop();
                    }
            
                    // Close audio context
                    if (audioContextRef.current) {
                        await audioContextRef.current.close();
                        audioContextRef.current = null;
                    }

                    // Stop streams after processing chunks
                    if (mediaStreamRef.current) {
                        mediaStreamRef.current.getTracks().forEach(track => track.stop());
                        mediaStreamRef.current = null;
                    }
                    if (screenStreamRef.current) {
                        screenStreamRef.current.getTracks().forEach(track => track.stop());
                        screenStreamRef.current = null;
                    }
            
                    // Complete the upload with retries
                    let uploadRetries = 0;
                    const maxUploadRetries = 3;
                    
                    while (uploadRetries < maxUploadRetries) {
                        try {
                            await uploadToS3();
                            break;
                        } catch (error) {
                            console.error(`Upload attempt ${uploadRetries + 1} failed:`, error);
                            uploadRetries++;
                            if (uploadRetries < maxUploadRetries) {
                                await new Promise(resolve => setTimeout(resolve, Math.pow(2, uploadRetries) * 1000));
                            } else {
                                throw error;
                            }
                        }
                    }
                } catch (error) {
                    console.error('Fatal error during recording processing:', error);
                    throw error;
                }
            };
    
            // Start processing in background
            try {
                await processRecordings();
            } catch (error) {
                console.error('Error during background processing:', error);
            }

            mediaRecorderRef.current = null;
            screenRecorderRef.current = null;
    
            // Close WebSocket connection
            if (websocketRef.current) {
                websocketRef.current.close();
                websocketRef.current = null;
            }
    
            // Stop speech recognition
            
    
        } catch (error) {
            console.error('Error during session end:', error);
        }
    };

    const handleAiResponse = (data) => {
        if (data.audio_url) {
            setCanUserRespond(false);
            setIsAiSpeaking(true);
    
            if (data.text) {
                setAiQuestion(data.text);
                streamText(data.text);
                // Add AI question to session history
                setSessionHistory(prev => [...prev, {
                    type: 'ai',
                    text: data.text,
                    timestamp: new Date().toISOString()
                }]);
            }
    
            const audioBlob = new Blob(
                [Uint8Array.from(atob(data.audio_url), c => c.charCodeAt(0))], 
                { type: 'audio/wav' }
            );
            const audioUrl = URL.createObjectURL(audioBlob);
            
            audioRef.current.src = audioUrl;
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
                setIsAiSpeaking(false);
                setCanUserRespond(true);
            });
    
            // if (isFirstQuestionRef.current) {
            //     initialSubmissionTimeoutRef.current = setTimeout(() => {
            //         // if (completeTranscript.trim() === '') {
            //             // setCompleteTranscript('hello');
            //         submitResponse();
            //         // }
            //     }, 2000);
            //     isFirstQuestionRef.current = false;
            // }
        }
    };

    const handleAudioEnded = () => {
        setIsAiSpeaking(false);
        setCanUserRespond(true);
        startRecognition();
        setCompleteTranscript('');
        
        
    };

    const startRecognition = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;

        recognitionRef.current.onresult = (event) => {
            let currentInterimTranscript = '';
            let finalTranscript = '';

            for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    finalTranscript += transcript + ' ';
                    setCompleteTranscript(prev => prev + transcript + ' ');
                } else {
                    currentInterimTranscript += transcript;
                }
            }

            setInterimTranscript(currentInterimTranscript);
            setTranscript(currentInterimTranscript || finalTranscript);
        };

        recognitionRef.current.onerror = (event) => {
            console.error('Speech Recognition Error:', event.error);
            if (event.error === 'no-speech') {
                recognitionRef.current.stop();
                recognitionRef.current.start();
            }
        };

        recognitionRef.current.start();
    };

    const submitResponse = () => {
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            const fullText = completeTranscript.trim();
            
            // if (fullText) {
                // Add user response to session history
            setSessionHistory(prev => [...prev, {
                type: 'user',
                text: fullText,
                timestamp: new Date().toISOString()
            }]);
            
            websocketRef.current.send(fullText);
            setTranscript('');
            setCompleteTranscript('');

            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
            // }
        }
    };

    const getConnectionStatusColor = () => {
        switch (connectionStatus) {
            case 'connected':
                return 'bg-green-500';
            case 'disconnected':
                return 'bg-red-500';
            case 'error':
                return 'bg-yellow-500';
            default:
                return 'bg-gray-500';
        }
    };

    return (
        <div className="p-4 max-w-2xl mx-auto">
            {/* Audio/Video Test Section */}
            {isTestMode ? (
                <div className="mb-4 p-4 bg-gray-100 rounded-lg">
                    <h3 className="text-lg font-semibold mb-2">Audio/Video Test</h3>
                    <p className="mb-4 text-gray-600">
                        Before starting the interview, please test your audio and video to ensure everything is working properly.
                    </p>
                    <button
                        onClick={testAudioVideo}
                        className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white mb-2"
                    >
                        Test Audio/Video
                    </button>
                    {isAudioVideoWorking !== null && (
                        <div className={`mt-2 ${isAudioVideoWorking ? 'text-green-500' : 'text-red-500'}`}>
                            {isAudioVideoWorking 
                                ? 'Audio and video are working properly! You can start the interview.'
                                : 'There seems to be an issue with your audio or video. Please check your settings.'}
                        </div>
                    )}
                    <button
                        onClick={() => setIsTestMode(false)}
                        className="px-4 py-2 rounded-lg bg-green-500 hover:bg-green-600 text-white mt-2"
                    >
                        Continue to Interview
                    </button>
                </div>
            ) : (
                <>
                    {/* Status and Timer Section */}
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                                <div className={`w-3 h-3 rounded-full ${getConnectionStatusColor()}`}></div>
                                <span className="text-sm">
                                    Status: {connectionStatus.charAt(0).toUpperCase() + connectionStatus.slice(1)}
                                </span>
                            </div>
                            {isAiSpeaking && (
                                <div className="flex items-center gap-2">
                                    <div className="w-3 h-3 rounded-full bg-blue-500 animate-pulse"></div>
                                    <span className="text-sm">AI Speaking...</span>
                                </div>
                            )}
                        </div>
                        {isRecording && (
                            <div className="text-lg font-semibold">
                                Time Remaining: {formatTime(timeRemaining)}
                            </div>
                        )}
                    </div>
    
                    {/* Video Preview */}
                    <div className="mb-4">
                        <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            muted
                            className="w-full h-64 bg-gray-200 rounded-lg object-cover"
                        />
                    </div>
    
                    {/* AI Question Display */}
                    {aiQuestion && (
                        <div className="mb-4 p-4 bg-blue-100 rounded-lg">
                            <h3 className="text-lg font-semibold mb-2">AI Question:</h3>
                            <p>{displayedQuestion}</p>
                        </div>
                    )}
    
                    {/* Control Buttons */}
                    <div className="flex gap-4 mb-4">
                        <button
                            onClick={!isRecording ? startMediaStream : null}
                            className={`px-4 py-2 rounded-lg ${
                                !isRecording && connectionStatus === 'disconnected'
                                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                                    : 'bg-gray-400 cursor-not-allowed text-white'
                            }`}
                            disabled={isRecording || connectionStatus !== 'disconnected'}
                        >
                            Start Session
                        </button>
    
                        <button
                            onClick={submitResponse}
                            className="px-4 py-2 rounded-lg bg-green-500 hover:bg-green-600 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={isAiSpeaking || !canUserRespond || !isRecording}
                        >
                            Submit Response
                        </button>
    
                        <button
                            onClick={endSession}
                            className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={!isRecording}
                        >
                            End Session
                        </button>
                    </div>
    
                    {/* Real-time Transcript */}
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold mb-2">Current Response:</h3>
                        <div className="p-4 bg-gray-100 rounded-lg min-h-24">
                            {completeTranscript}
                            <span className="text-gray-600">{interimTranscript}</span>
                        </div>
                    </div>
    
                    {/* Session History */}
                    <div className="mt-4">
                        <h3 className="text-lg font-semibold mb-2">Session History:</h3>
                        <div className="p-4 bg-gray-100 rounded-lg min-h-24 max-h-96 overflow-y-auto space-y-4">
                            {sessionHistory.length > 0 ? (
                                sessionHistory.map((entry, index) => (
                                    <div 
                                        key={index} 
                                        className={`p-3 rounded-lg ${
                                            entry.type === 'ai' 
                                                ? 'bg-blue-100 mr-12' 
                                                : 'bg-green-100 ml-12'
                                        }`}
                                    >
                                        <div className="font-semibold mb-1">
                                            {entry.type === 'ai' ? 'AI Question:' : 'Your Response:'}
                                        </div>
                                        <div>{entry.text}</div>
                                        <div className="text-xs text-gray-500 mt-1">
                                            {new Date(entry.timestamp).toLocaleTimeString()}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-gray-500">No conversation history yet...</div>
                            )}
                        </div>
                    </div>
    
                    {/* Status Messages */}
                    {!canUserRespond && !isAiSpeaking && (
                        <div className="mt-4 p-4 bg-yellow-100 rounded-lg">
                            Waiting for AI response...
                        </div>
                    )}
    
                    {isUploading && (
                    <div style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        textAlign: 'center',
                        zIndex: 1000
                    }}>
                        <div>Uploading Interview Recording...</div>
                        <div style={{
                            width: '300px',
                            height: '20px',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            margin: '10px 0'
                        }}>
                            <div style={{
                                width: `${uploadProgress}%`,
                                height: '100%',
                                backgroundColor: '#4CAF50',
                                transition: 'width 0.3s ease-in-out'
                            }}></div>
                        </div>
                        <div>{Math.round(uploadProgress)}%</div>
                    </div>
                )}
                </>
            )}
        </div>
    );
};

export default Videoframe;
