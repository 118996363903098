import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import api from '../services/api';
import ListingModal from './ListingModal';
import { Link } from 'react-router-dom';
import CandidatesList from './CandidatesList';

const Home = () => {
  const { user, logout } = useAuth();
  const [userData, setUserData] = useState(null);
  const [listings, setListings] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, listingsResponse] = await Promise.all([
          api.get('home/'),
          api.get('listings/')
        ]);
        setUserData(userResponse.data);
        setListings(listingsResponse.data);
      } catch (err) {
        setError('Failed to fetch data');
      }
    };
    fetchData();
  }, []);

  const handleCreateListing = async (formData) => {
    try {
      const response = await api.post('listings/create/', formData);
      setListings([response.data, ...listings]);
    } catch (err) {
      throw err;
    }
  };

  const handleListingClick = (listing) => {
    setSelectedListing(selectedListing?.id === listing.id ? null : listing);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-xl font-bold">Welcome, {user?.username}!</h1>
          <div className="space-x-4">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              New Listing
            </button>
            <button
              onClick={logout}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        </div>
        <Link
          to="/change-password"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-4"
        >
          Change Password
        </Link>
      </nav>

      <div className="max-w-7xl mx-auto mt-8 p-4">
        {error && <div className="text-red-500 mb-4">{error}</div>}

        {userData && (
          <div className="bg-white shadow rounded p-6 mb-8">
            <h2 className="text-2xl font-bold mb-4">Your Profile</h2>
            <div className="space-y-2">
              <p><strong>Username:</strong> {userData.user_data.username}</p>
              <p><strong>Email:</strong> {userData.user_data.email}</p>
              <p><strong>Full Name:</strong> {userData.user_data.full_name}</p>
              <p><strong>Verified:</strong> {userData.user_data.is_verified ? 'Yes' : 'No'}</p>
            </div>
          </div>
        )}
        <div className="bg-white shadow rounded p-6">
          <h2 className="text-2xl font-bold mb-4">Your Listings</h2>
          <div className="space-y-4">
            {listings.map((listing) => (
              <div key={listing.id} className="border p-4 rounded">
                <p><strong>Job Title:</strong> {listing.job_title}</p>
                <p><strong>Token:</strong> {listing.token}</p>
                <p><strong>Emails:</strong> {listing.emails}</p>
                <p><strong>Requirements:</strong> {listing.requirements}</p>
                <p><strong>Valid Until:</strong> {new Date(listing.expires_at).toLocaleDateString()}</p>
              </div>
            ))}
            {listings.length === 0 && (
              <p className="text-gray-500">No listings yet.</p>
            )}
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto mt-8 p-4">
        <div className="bg-white shadow rounded p-6">
          <h2 className="text-2xl font-bold mb-4">Your Listings</h2>
          <div className="space-y-4">
            {listings.map((listing) => (
              <div key={listing.id}>
                <div 
                  className="border p-4 rounded cursor-pointer hover:bg-gray-50"
                  onClick={() => handleListingClick(listing)}
                >
                  <p><strong>Token:</strong> {listing.token}</p>
                  <p><strong>Emails:</strong> {listing.emails}</p>
                  <p><strong>Requirements:</strong> {listing.requirements}</p>
                  <p><strong>Valid Until:</strong> {new Date(listing.expires_at).toLocaleDateString()}</p>
                </div>
                
                {selectedListing?.id === listing.id && (
                  <div className="mt-4 ml-4">
                    <CandidatesList listingToken={listing.token} />
                  </div>
                )}
              </div>
            ))}
            {listings.length === 0 && (
              <p className="text-gray-500">No listings yet.</p>
            )}
          </div>
        </div>
      </div>
      <ListingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCreateListing}
      />

    </div>
  );
};

export default Home;